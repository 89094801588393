<template>
  <v-container style="padding:1px">
    <v-img :src="require('../assets/images/1.png')" />
      <iframe width="100%" height="734" src="https://datastudio.google.com/embed/reporting/d336ffe2-f0b8-481a-83c5-7871fc374c64/page/fJR0B" frameborder="0" style="border:0" allowfullscreen></iframe>
    <v-card
      class="mx-auto mt-5"
      style="
        direction: rtl;
        font-family: Open Sans, Gadget, sans-serif;
        color: #000000;
      "
    >
      <v-card-title>
        <h1 class="display-1">מדינת הציוצים</h1>
      </v-card-title>
      <v-card-subtitle
        style="
          line-height: 1.38;
          margin-bottom: 0pt;
          font-size: 24px;
          font-weight: bold;
          color: #000000;
        "
      >
        <h2>סיכום שנה ברשת הטוויטר בישראל</h2>
      </v-card-subtitle>
      <v-card-text
        style="
          line-height: 1.38;
          margin-bottom: 0pt;
          font-size: 20px;
          color: #000000;
        "
      >
        <p>
          שנת 2021 הייתה שנה סוערת ולא פשוטה. אתגרים ואירועים ובהם הקורונה, מבצע
          החיסונים הראשון, הדחתו של טראמפ, מערכת הבחירות הגועשת בישראל. מבצע
          ״שומר חומות״, אסון מירון, חילופי השלטון והתינוק של מיכאלי ושליין, גרמו
          לרשת הטוויטר לצייץ בלי הפסקה ולשבור שיא חדש של המולה.
        </p>
        <p>מה היו הציוצים ששברו את הרשת בשנת 2021?</p>
        <p>מיהם המשפיענים המובילים בטוויטר ומה סוד הקסם שלהם?</p>
        <p>מיהם הפוליטיקאים שמעוררים הכי הרבה ביקורת וחוסר אהדה?</p>
        <p>ומה הייתה התפנית החדה ברשת מאז הקמת הממשלה בחודש יוני?!</p>
        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <p>הטוויטר היא פלטפורמה אקטואלית, פעילה ותוססת.</p>
        <p>
          אין כמעט פוליטיקאי או איש תקשורת בישראל שאינו מפרסם בה את ציוציו,
          דעותיו והגיגיו. האווירה היא סופר קז׳ואל, אמוציונאלית לרוב, ״התנגחותית״
          לפעמים. לנעשה בטוויטר יש חשיבות בלתי מבוטלת בקביעת סדר היום התקשורתי
          והציבורי, בניבוי דעת הקהל ובעיצוב תרבות הדיון בישראל.
        </p>
        <p>
          ועדיין, באופן אולי מעט מפתיע, רוב הציבור הרחב אינו פעיל ברשת הטוויטר,
          ולא מודע למתרחש בה.
        </p>
        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <p>
          במהלך השנה, מערכת Tweetboard סרקה מיליוני ציוצים ברשת הטוויטר בישראל.
          המערכת שולפת את נתוני הרשת דרך ה-API הרשמי של טוויטר ומנתחת אותם לפי
          נושאים. הצצה לחלק מהנתונים שיפורסמו בקרוב בדוח השנתי המלא של 2021,
          מביאה טעימה קטנה אך מייצגת, ממה שקורה בעולם המקביל של ישראל- המקום שבו
          280 תווים יכולים להפוך עולמות, מגישי חדשות משחיזים מילים ומורידים
          עניבות, וכל צייצן אנונימי יכול להטריל ראשי ממשלות. ברוכים הבאים אל
          מדינת הציוצים!
        </p>
        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <h2>כוחה של רשת הטוויטר</h2>
        <p>
          טוויטר היא רשת חברתית אשר הוקמה בשנת 2006. בתחילת דרכה, השתרשה רשת
          הטוויטר כרשת חברתית של אושיות ו״סלבס״, והתפרסמה בעיקר כפלטפורמה עבור
          מעריצים שרצו לעקוב אחר הידוענים אותם הם אוהבים. לא עבר זמן רב עד
          שפוליטיקאים החלו להשתמש בטוויטר על מנת לגייס דעת קהל, והטוויטר הפכה
          לזירה עם ניחוח פוליטי, אקטואלי ודינמי מאד. תעשיית הבידור נדחקה הצידה
          (מה שככל הנראה הוביל לביסוסה של רשת האינסטגרם כפלטפורמת החשיפה הטבעית
          יותר לידוענים).
        </p>
        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <p>
          בשנים האחרונות, ניתן לראות כיצד הרשתות החברתיות משפיעות על סדר היום
          התקשורתי והציבורי. לעתים יש להן כוח אף להכריע בחירות או להצית מאבקים
          ומהפכות חברתיות כדוגמת ״האביב הערבי״, גל של התקוממויות והפגנות אזרחיות
          שהחל בטוניס ב-2010 והביא למהפכה של ממש במשטרים בעולם הערבי.
        </p>
        <p>
          דוגמה נוספת ניתן לראות בפעילותו של נשיא ארה״ב לשעבר, דונלד טראמפ ברשת
          הטוויטר. ניתן לומר כי טראמפ ניהל את מערך הדוברות ויחסי הציבור שלו
          במידה רבה דרך ציוציו ברשת הטוויטר. ממסרים מזדמנים ופשוטים שהופנו אל
          הציבור האמריקאי, ועד לאיומים מפורשים על מדינות זרות. בכך העביר טראמפ
          את רשת הטוויטר מרשת של דעות אלמוניות, אל אור הזרקורים, והפך אותה למקור
          לסיקור תקשורתי רחב. אגב, בסופו של דבר, בתחילת שנת 2021- החליטה טוויטר
          על סגירת חשבונו של טראמפ מחמת ״סכנה להמשך הסתה לאלימות"...
        </p>
        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <p>
          במדינת ישראל ישנם יותר מחצי מיליון משתמשים פעילים. זו אמנם לא כמות
          גדולה ביחס לרשתות מתחרות (ברשת האינסטגרם ישנם כ- 4 מיליון משתמשים
          פעילים בישראל ובפייסבוק כ-7 מיליון משתמשים פעילים בישראל).
        </p>
        <p>ואולם, עדיין כוחה של זירת הטוויטר בעיצוב דעת הקהל הוא עצום.</p>
        <p>
          נציגי ציבור ובעלי תפקידים הקובעים כיצד יראו החיים במדינת ישראל מתקשרים
          בה על בסיס יום יומי. לא נדיר למצוא ברשת ויכוחים בין אנשי תקשורת
          ועיתונאים, לקרוא ציוצים מכוננים של מנהיגי מדינות ובעיקר להיחשף לביקורת
          אמיתית מהשטח על פעולות השלטון. ראשי ממשלה, שרים, חברי כנסת/
          ״נורווגים״, אושיות רשת אהודות וכמה מהאנשים החזקים במשק מייצרים בצו
          השעה דיון קולח ובועט, שלא ניתן להיחשף אליו בשום במה אחרת בעולם.
        </p>
        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <p>
          בשל ״חוקי הפורמט״, המחייבים מסרים קצרים וקולעים, כמות הציוצים היומית
          היא עצומה, והדיונים בטוויטר מהירים, דינמים, סוחפים ומתחדשים ללא הרף.
          לא בכדי הפכה הטוויטר לזירה החמה, התוססת והאקטואלית ביותר.
        </p>
        <p>
          כשסוקרים לעומק ומתעדים את הפעילות ברשת הטוויטר, ניתן לקבל מעין מפה או
          תמונת מצב של האווירה המדינית, החברתית והתרבותית של מדינת ישראל לאורך
          זמן.
        </p>
        <p>
          כך לדוגמה, בבחינה של הציוצים בעלי כמות הלייקים, השיתופים והתגובות
          הגדולה ביותר, ניתן לראות מה היו הנושאים המעניינים והאירועים המשמעותיים
          במהלך השנה, וכיצד הגיב הציבור.
        </p>

        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <h2>הציוצים הפופולארים</h2>
        <p>
          <b>מקום 1:</b>
          ציוץ של הקומיקאי נדב נווה, עם סרטון מצחיק שהצליח לשבור שיא חדש בטוויטר
          ישראל. בסרטון ביצע נווה חיקוי של ראש הממשלה לשעבר, בנימין נתניהו,
          בנאומו על מגבלות הקורונה והחיסונים.
        </p>
        <p>הסרטון ״שבר את הרשת״ וגרף מעל 31 אלף לייקים בטוויטר.</p>
        <v-img
          :src="require('../assets/images/2021/tweet1.png')"
          style="width: 400px"
        />

        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <Tweet id="1475918396948688896"></Tweet>

        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <p>
          <b>מקום 2:</b> ציוץ עם סרטון מרחבת הכותל ששיתף חה״כ איימן עודה בחודש
          מאי.
        </p>
        <p>
          עודה כתב ״מזעזע״ והעלה סרטון המציג אשליה לפיה יהודים כביכול שורפים את
          מסגד אל אקצא ורוקדים משמחה עם דגלי ישראל.
        </p>
        <p>הסרטון גרף כ-15 מיליון צפיות וכמעט 30 אלף לייקים.</p>
        <p>
          הציוץ עורר זעם ומחאה ציבורית. ביקורות רבות נשמעו על כך שמה ש״מזעזע״
          הוא שנציג ציבור בכנסת ישראל מעלה סרטון שקרי שמסית נגד מדינת ישראל.
          טענה רווחת הנה כי סרטון זה היווה את אחד הטריגרים הראשונים והמשמעותיים
          לפריצתם של פרעות הערבים בערים המעורבות ומעשי אלימות שעלו בחיי אדם,
          בפציעות ובנזק רב לרכוש - ונזק רב יותר לחיי הדו-קיום השבריריים מלכתחילה
          בערים המעורבות.
        </p>
        <p>
          זוהי אולי הדוגמה ההרסנית ביותר לכוחה המסוכן של ההסתה ברשתות החברתיות,
          ולחשיבות האחריות של נבחרי הציבור בבחירת המסרים אותם הם בוחרים לקדם.
        </p>
        <v-img
          :src="require('../assets/images/2021/tweet2.png')"
          style="width: 400px"
        />
        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <Tweet id="1475918396948688896"></Tweet>

        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <p>
          <b>מקום 3-4:</b> ציוץ עם סרטון מרחבת הכותל ששיתף חה״כ איימן עודה בחודש
          מאי.
        </p>
        <p>
          שני ציוצים של ראש הממשלה לשעבר בנימין נתניהו בתקופה שלאחר הפסדו
          בבחירות.
        </p>

        <p>
          ציוצו ביום השבעת הממשלה החדשה (תמונתו ולצדה הכיתוב ״אוהב אתכם, תודה!״)
          גרף מעל ל-17 אלף לייקים, וציוץ נוסף שפורסם מספר ימים לאחר מכן (ובו
          הכיתוב ״אוהב אתכם! שבת שלום לכל אזרחי ישראל״, גרף מעלה מ-15 אלף
          לייקים.
        </p>

        <p>
          מעניין לציין כי שני הציוצים של בנימין נתניהו, אשר גרפו את כמות האהדה
          הגדולה ביותר, היו מיד לאחר שהפסיד את השלטון. נראה כי ציבור תומכיו בחר
          להביע את אהדתו ונאמנותו, דווקא בשעת המשבר.
        </p>

        <v-img
          :src="require('../assets/images/2021/tweet3.png')"
          style="width: 400px"
        />
        <v-img
          :src="require('../assets/images/2021/tweet4.png')"
          style="width: 400px"
        />
        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <Tweet id="1475918396948688896"></Tweet>
        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <p>
          <b>מקום 5:</b> ציוץ של יאיר לפיד מחודש יוני שגרף מעל ל-15 אלף לייקים
          ובו שתי מילים בלבד: ״עלה בידי״.
        </p>

        <p>
          מדובר למעשה בציוץ הראשון מתוך סדרה של ציוצים של לפיד, אשר הציגו את
          תחילתה של הקמת ממשלת בנט-לפיד.
        </p>

        <p>
          חילופי השלטון לא הותירו את הציבור אדיש, ויאיר לפיד, שעד לנקודת זמן זו
          עפ״י רוב היה מקבל כאלף עד אלפיים לייקים בממוצע לציוץ, קיבל כ-10 אלף
          לייקים בממוצע לכל ציוץ שפרסם באותו שבוע.
        </p>

        <v-img
          :src="require('../assets/images/2021/tweet5.png')"
          style="width: 400px"
        />

        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <h2>המשפיענים ומעצבי דעת הקהל</h2>
        <p>
          מרביתה של הפעילות במסגרת רשת הטוויטר, כמו גם ברשתות אחרות- מתנהלת לרוב
          סביב קבוצת משתמשים מובילים, המכונים ״אושיות רשת״. מדובר בקבוצה של
          משתמשים מוכרים ואקטיביים, בעלי מספר עוקבים רב ויכולת לעורר דיונים
          ושיעור מעורבות גבוה (engagement). אושיות הרשת לרוב אוחזים במשנה או
          באג׳נדה כלשהי. לעתים הם מבקשים לקדם אינטרס או רעיון מסוים, ולעתים רק
          את עצמם. המסרים שהם מפרסמים מכתיבים את נושאי הדיון ברחבי הטוויטר
          וקובעים למעשה את סדר היום ברשת. לעתים יש בהם אף כדי להשפיע בהתווית דעת
          קהל בנושא מסוים או לסחוף אחריהם את עוקביהם לפעולה מסוימת, כדוגמת החרם
          הצרכני שהובל ברשת נגד בן אנד ג׳ריס (שבוטל לבסוף). אין ספק כי מדובר
          בכוח השפעה בלתי מבוטל השמור לרוב לנציגי הציבור, לאנשי תקשורת ועיתונאים
          ולידוענים. ואולם בטוויטר אין זה נדיר כלל כי גם משתמשים שזהותם אינה
          ידועה (אנונימיים/״פייק״)- יכולים לצבור כוח השפעה רב.
        </p>
        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <h2>רשימת המשפיענים בטוויטר לשנת 2021 - הפוליטיקאים</h2>
        <p>
          ציינו כי הטוויטר היא רשת פוליטית ואקטואלית מאד. השחקנים המובילים בזירה
          הפוליטית בשנת 2021 הם נציגי הציבור שהצליחו לייצר את העניין ואת שיעור
          המעורבות הגבוה ביותר, הנמדד בעיקר בכמות הלייקים, התגובות והשיתופים להם
          זכו המסרים שפרסמו לאורך השנה.
        </p>
        <p>
          ם בשנת 2021 ואף לאחר החלפת הממשלה, ניתן לראות כי בנימין נתניהו מוביל
          את הטבלה בפער ניכר מול שאר הפוליטיקאים הפעילים בטוויטר. אחריו מדורג
          יאיר לפיד, ובמקום השלישי, גלית דיסטל אטבריאן, שהגיחה אל רשת הטוויטר רק
          באמצע שנת 2021 ובמהירות בזק הצליחה לצבור עוקבים ותהודה ציבורית רחבה.
        </p>
        <p>
          רה״מ נפתלי בנט ממוקם במקום הרביעי, ולאחריו שלושת חברי מפלגת הציונות
          הדתית: בצלאל סמוטריץ, איתמר בן גביר ושמחה רוטמן, וכן עמיחי שיקלי,
          שחידש את פעילותו ברשת רק סביב הקמת הממשלה, אך מאז מצליח לסחוף אחריו את
          קהל תומכיו ולצמוח באופן מהיר ועקבי.
        </p>
        <p>
          סוגרים את רשימת עשרת הפוליטיקאים המובילים בטוויטר- מרב מיכאלי ויאיר
          גולן.
        </p>
        <v-img :src="require('../assets/images/2021/toppolitics.png')" />
        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <h2>רשימת המשפיענים בטוויטר לשנת 2021 - אנשי תקשורת ואושיות רשת</h2>
        <p>
          איש התקשורת ינון מגל, הדמות הדומיננטית ביותר בהובלת השיח הימני
          בטוויטר- ממוקם ראשון בדירוג אנשי התקשורת ואושיות הרשת המשפיעים של
          הטוויטר.
        </p>
        <p>
          אחריו מדורג עמית סגל, המצייץ מעט יחסית, אך כל ציוץ שלו מוביל לשרשרת של
          תגובות ודיונים במרחב הטוויטר.
        </p>
        <p>שמעון ריקלין, המוביל קו ימני ״נוקשה״ יותר מינון מגל, מדורג שלישי.</p>
        <p>
          במקום הרביעי ממוקם יבגני זרובינסקי- אושית רשת אהודה במיוחד, שמוביל
          בציוצים בעלי אופי הומוריסטי, שנון ובקורתי, ומצייץ לא מעט בנושאי
          אקטואליה ופוליטיקה.
        </p>
        <p>
          בן כספית, שותפו של ינון מגל לתוכנית הבוקר ומוביל צייצני מחנה השמאל
          בטוויטר, תפס את המקום החמישי.
        </p>
        <p>
          במקום השישי מדורג חיים לוינסון, גם הוא אוחז בדעות מהצד השמאלי של המפה,
          המעלה לרשת דיונים המבוססים על הטור השבועי שלו. בביצת הטוויטר הקטנה, יש
          הנוהגים לכנות את לוינסון בכינוי ״טרוֹל״- מונח נפוץ בסלנג האינטרנט
          המתאר גולש המפיק הנאה מפעולות מתסיסות ברשת כגון פרסום תגובות סרק,
          התחקות או ״רדיפה״ אחר משתמש מסוים, שימוש בציניות מוגזמת וכד׳.
        </p>
        <p>
          בדירוגים 7-10 ממוקמים עקיבא נוביק, יותם זימרי, ישראל פריי ונועם פתחי.
        </p>

        <v-img :src="require('../assets/images/2021/topusers.png')" />
        <v-divider style="padding-top: 25px !important" inset></v-divider>

        <h2>רשימת ״השנויים במחלוקת״ בטוויטר לשנת 2021</h2>
        <p>
          לצד המשפיענים והמשתמשים הגורפים תמיכה רבה ברשת, ניצבת רשימה פחות נוצצת
          ובה לא בטוח שתרצו לקחת חלק (אלא אם כן אם אתם דוגלים במוטו שאין דבר כזה
          פרסום שלילי).
        </p>
        <p>
          ברשימה מדורגים המשתמשים שאוספים למסרים שלהם תגובות רבות יותר מלייקים,
          או במילים אחרות, האנשים שמצליחים לעצבן את גולשי הטוויטר ולעורר מחלוקת.
          בניתוח רגשי, המדד מסמל חוסר תמיכה במסר הנאמר בציוץ, או בצייצן עצמו.
        </p>
        <p>
          את רשימת ״השנויים במחלוקת״ לשנת 2021, מובילים באופן כמעט מוחלט חברי
          מפלגת ״ימינה״ וחברי מפלגת ״תקווה חדשה״. מגמה זו שתועדה בסיכום השנתי,
          נצפתה במהלך השנה בריבוי של פרסום תגובות זועמות, המביעות אי אמון ו/או
          אי שביעות רצון ממהלכים פוליטיים או מדיניים בקרב חברי שתי המפלגות.
        </p>
        <p>
          הדבר בלט במיוחד לאחר הבחירות והקמת הממשלה, כאשר נשמעה בקורת רועמת על
          ביצוע פעולות בניגוד להבטחות הבחירות. כך לדוגמה, צבי האוזר ויועז הנדל,
          שספגו לאחר ממשלת נתניהו-גנץ אש מהצד השמאלי של המפה, החלו לאחר הקמת
          הממשלה הנוכחית, לשמוע התנגדויות זועמות מכיוון ימין.
        </p>
        <p>
          בפרסום דוחות הטוויטר במהלך השנה, רבים תהו כיצד יתכן שדווקא מפלגת
          השלטון היא זו הסופגת את חוסר התמיכה הגדול ביותר ברשת וטענו כי ספק אם
          מצב דברים זה מתקיים במדינה דמוקרטית אחרת.
        </p>
        <v-img
          :src="require('../assets/images/2021/topbadusers.png')"
          style="width: 400px"
        />
        <v-divider style="padding-top: 25px !important" inset></v-divider>

        <h2>״הכל פייק״</h2>
        <p>
          ישנם אנשים הטוענים כי זירת הטוויטר ״מכורה״ וכי הימין, המסתמן כצד
          הפוליטי המוביל בדירוגי המשפיענים בטוויטר, משתמש בבוטים (משתמשים
          רובוטיים או אוטומטיים), מפעיל תעמולה משומנת היטב בצורת ״גדודים של
          צייצנים״ או רוכש לייקים.
        </p>
        <p>
          צריך לצאת מההגדרות הללו של פייקים, בוטים ומשתמשים מומצאים כאילו מדובר
          במכונה אוטומטית שמשתלטת על הרשתות החברתיות והיא זו המטה את התמיכה או
          חורצת את התדר הפוליטי בקרב הציבור.
        </p>
        <p>
          יש להבין כי אין סבירות שציוץ כלשהו יעורר בקורת קשה שאינה ברובה
          ספונטנית ואותנטית. זה נכון שניתן ״לרכוש״ לייקים ועוקבים ולשחק עם
          המספרים בטווח קצר, אולם תמונת מצב מוטה בטווח הארוך לא תתאפשר, שהרי
          עוקבים ״רדומים״ לא מייצרים תנועה לאורך זמן.
        </p>
        <p>
          מה גם שניסיונות התערבות שכאלה מתקיימים משני צדי המפה הפוליטית, וממילא
          ההשפעה שלהם נמוכה בהרבה מאשר תמיכה אותנטית בזמן אמת של משתמשים בשר
          ודם, שהם היצרנים הבלעדיים האמיתיים של תוכן ושל קשרים ברשת.
        </p>
        <p>
          מובן שישנן התארגנויות נקודתיות על מנת לקדם תוכן מסוים, כדוגמת קבוצת
          המשתמשים בהובלת ד״ר אדי כהן, שנעזרים בכוחם המשותף בפרסום תגובות בשפה
          הערבית נגד מסרים מסיתים. כך למשל משוחחים החברים בינהם ומדווחים
          בוואטסאפ הקבוצתי כשהם נתקלים בפייק ניוז של BDS ומתגייסים מיד להסברה.
        </p>
        <p>
          ועדיין, התארגנויות שכאלה אינן מעידות על הטיה ברשת, אלא אולי דווקא על
          תנועה הפוכה- של זליגה מהתאגדויות מתוך החיים האמיתיים- אל תוך רחבי
          הרשת.
        </p>
        <p>
          נראה כי הבקשה להדוף ביקורת פוליטית באמתלה כי מדובר ב״מתקפת בוטים״,
          לרוב מהווה עצימת עיניים או ניסיון להתחמק מן האמת.
        </p>
        <v-divider style="padding-top: 25px !important" inset></v-divider>

        <h2>תגובת הטוויטר למהפך הפוליטי ומבט קדימה</h2>
        <p>
          במהלך השנה האחרונה בטוויטר, נרשמה ירידה דרמטית בתמיכה בפוליטיקאים מן
          הצד השמאלי של המתרס, וזאת לאחר הקמת הממשלה.
        </p>
        <p>
          סברה אחת גרסה כי מדובר בתוצאה טבעית, עקב ירידת מתח והפחתה בשימוש
          בטוויטר ובפרסום הציוצים לאחר הבחירות. ואולם בבדיקה מעמיקה נמצא כי כמות
          הציוצים הממוצעת, בקרב שני המחנות הפוליטיים, לא ירדה- אלא נשמרה עקבית
          גם לאחר הבחירות. ועדיין, ממוצע הלייקים בקרב המחנה השמאלי, ירד משמעותית
          לאחר הקמת הממשלה. זאת בהשוואה לכמות הלייקים לציוצים של פוליטיקאים מן
          הימין, שלא ירדה כלל ואף עלתה.
        </p>
        <p>
          יתכן והסיבה לירידה בתמיכה הנ״ל, נעוצה בעובדה שלאחר הקמת הממשלה-
          המשתמשים בעלי נטייה פוליטית שמאלה חוו ירידה במוטיבציה לתמוך במועמדים
          שלהם, לאחר שבעצם הגשימו את המטרה בהחלפת השלטון.
        </p>
        <p>
          הסיבה השנייה יכולה להיות מחוברת לפוזיציה. כחלק ממתנגדי השלטון, קל יותר
          להלין כי הממשלה נוקטת בהגבלות קורונה מוגזמות, מבצעת איכוני שב״כ,
          מבזבזת את כספי הציבור בממשלה מנופחת עם נורווגים וכד׳, ואילו בהתהפכות
          היוצרות, כחלק מתומכי הקואליציה- קשה יותר (או מעניין פחות), לנסות לתרץ
          את החלטות הנתפסות כשגויות או הפרות של הבטחות בחירות.
        </p>
        <v-img :src="require('../assets/images/2021/usersdown.png')" />
        <p>
          פרשנים רבים מדברים לאחרונה על ניוד קולות ״ימניים״ מקרב קהל התומכים של
          ״ימינה״ ו״תקווה חדשה״ אל עבר מפלגת ימין-מרכז חדשה שתוקם, אולי בראשותו
          של עמיחי שיקלי.
        </p>
        <p>אני מזהה מהלך אחר.</p>
        <p>
          אם יש במתרחש בזירת הטוויטר כדי לנבא את הלך הרוחות האמיתי בקרב הציבור,
          ואני בהחלט מאמין בכך, נראה כי אנשי הימין, אשר נכוו מתוצאות הבחירות
          האחרונות, לא ימהרו שוב לתת הזדמנות למפלגות המנסות לאתגר את הליכוד
          והעלולות למעשה לסכן את גוש הימין.
        </p>
        <p>
          הימין ״מצופף שורות״. אין לו את הפריבילגיה יותר לנסות משהו חדש. להתפזר.
          ללכת אחרי מפלגות צדדיות או מפלגות לווין. בבחירות הבאות, המצביעים
          הימניים יבקשו כנראה להיצמד למפלגות ה״בטוחות״ ויאלצו לבחור מפלגה גדולה,
          גם אם אינם שלמים עמה לחלוטין: הליכוד, ש״ס, יהדות התורה או הציונות
          הדתית.
        </p>
        <p>
          המפלגות בראשותם של בנט וסער ככל הנראה ימחקו מן המפה הפוליטית. כיום
          בטוויטר, קשה למצוא להן תומכים ימניים אמיתיים, ומעט התמיכה שנצפית עבורן
          מגיעה בעיקר ממצביעים ממחנה השמאל, שלא יבחרו בהם ממילא.
        </p>
        <p>
          מצד שני, ניתן לראות מגמה ברורה של אי שביעות רצון בקרב מצביעי גוש
          השמאל-מרכז מנציגי המפלגות בקואליציה. מחאה אדירה נרשמה, כאשר השר
          הורוביץ הצביע בעד איכוני שב״כ. מחאה אחרת, שאמנם פחות נשמעה בקרב מחנה
          השמאל, נתבקשה בשעה שיאיר לפיד, בניגוד לכל הצהרותיו והבטחותיו טרם
          הבחירות, הקים ממשלה מנופחת ומינה מקורבים לתפקידים בכירים.
        </p>
        <p>
          אמנם, בקרב חברי הקואליציה או בערוצי התקשורת, לא נשמעת בקורת כמעט על
          מחדלים וכשלים בתפקודה של הממשלה, מתוך אינטרסים מובנים, אך ברשת
          ,המיטיבה יותר לייצג את כלל צדי המתרס, כבר ברור למדי כי יש קהל גדול
          וראוי, שמחפש מפלגת שמאל-מרכז חדשה והגונה, בעלת ערכי יסוד אמיתיים שלא
          מתגמשים עם קבלת הכסא, כזו שתעמוד בהבטחות שלה לבוחריה ותהיה מונעת מחזון
          שהוא לטובת המדינה, ולא לטובת האחיזה בשלטון בכל מחיר.
        </p>
        <p>
          נראה כי מפלגה חדשה ונבונה שתעמיד אלטרנטיבה ערכית אמיתית, תתגלה כזו
          הקונה את עולמה בבחירות הבאות.
        </p>
        <v-divider style="padding-top: 25px !important" inset></v-divider>

        <v-divider style="padding-top: 25px !important" inset></v-divider>

        <h2>להיות עם חופשי?</h2>
        <p>
          שבונו של היוזר המשפיע ביותר בעולם, נשיא ארה״ב לשעבר, נחסם בתחילת השנה.
        </p>
        <p>
          בסופה של שנת 2021, הנושא על הטלת הגבלות על התכנים ברשתות החברתיות עולה
          לדיון גם בבתי המשפט בישראל. קשה לשער כיצד תיראנה השנים הקרובות בטוויטר
          וברשת בכלל.
        </p>
        <v-img
          :src="require('../assets/images/2021/tweetamit.png')"
          style="width: 400px"
        />
        <p>
          מובן כי לצד אינספור האפשרויות הטמונות בבמה פתוחה ובלתי אמצעית בה כל
          אדם רשאי לבטא את דעתו בצורה אמיתית ומשוחררת, קיימת גם סכנה.
        </p>
        <p>
          עוד מובן כי אין ספק כי תרבות הדיון בטוויטר, וברשת בכלל, צריכה להשתנות
          באופן מהותי. אסור בשום פנים לתת מקום להסתה, לאלימות ולבריונות רשת. אך
          האם יש צורך בקביעת חוקים והגבלות משפטיות הפוגעות בחופש הביטוי, או
          שהסוד האמיתי, כמו ברוב הדברים המהותיים בחיים, טמון באחריות אישית
          ובחינוך?
        </p>
        <p>
          אפשר לחתור לתרבות דיון נקייה בין מחנות ואנשים, גם כשאינם מסכימים זה עם
          זה.
        </p>
        <p>
          בשביל זה ראוי שקודם כל נציגי הציבור שלנו יקחו אחריות על האופן שבו הם
          מתנהלים ברשתות החברתיות, ובכלל בעצם.
        </p>

        <v-divider style="padding-top: 25px !important" inset></v-divider>

        <h2>המדריך לצייצן המתחיל</h2>
        <p>
          רשימת הציוצים הפופולאריים ורשימת המשפיענים ברשת הטוויטר שהוצגה בכתבה
          זו היא חלקית. את הרשימות המלאות, ניתוחים מיוחדים ודירוג מאות הצייצנים
          המובילים ניתן למצוא בעמוד של @Lmalenboim. מוזמנים להיכנס ולעקוב.
        </p>
        <p>
          אבל אם בינתיים חשקה נפשכם להצטרף לרשת הדינמית והתוססת בעולם, או שאתם
          כבר אוחזים בחשבון טוויטר ופשוט לא מצליחים להמריא, הנה כמה המלצות כיצד
          לגייס עוקבים, לעורר עניין ולהצליח לאסוף לייקים ותגובות. ומי יודע, אולי
          אפילו תצליחו להשתרבב לרשימת המשפיעים המלאה של Tweetboard בשנה הבאה.
        </p>
<h3>המלצות: </h3>
        <p>
            <b>1. תהיו פעילים </b>
            כדי לקחת חלק פעיל בטוויטר, ובכן, צריך להיות פעיל מאד בטוויטר. חשוב להיות מעודכנים בנושאים החמים, לעקוב אחר משתמשים מובילים, להגיב למשתמשים נוספים כל הזמן. ברשת חברתית המבוססת יותר מכל רשת אחרת על הבעת דעה, ברור שהקשר עם משתמשים אחרים הוא חשוב ביותר. ההצלחה תלויה במי שאתם עוקבים אחריהם, ובעוקבים שלכם. תעקבו, תפרגנו, תגיבו, תלייקו, תרטווטו, תעלו נושאים לדיון והעיקר שתקחו חלק פעיל. 

        </p>
         <p>
            <b>2. תהיו מתוזמנים </b>
חשוב להגיב ולפרסם ציוצים על הנושאים שמעניינים את הרשת בזמן אמת, ולא לחכות לסוף היום, כשרמת העניין כבר דועכת. 
        </p>
           <p>
            <b>3. תהיו אמיתיים </b>
אותנטיות היא מצרך מבוקש ומוערך מאד ברשת. כתבו את מה שיש לכם להגיד באמת, ולא רק את מה שנראה לכם כפופולרי.  ככל שתחשפו יותר מעצמכם, הקשרים שתיצרו יהיו אמיתיים ויגיבו אליכם יותר.
        </p>
         <p>
            <b>4. תהיו עקביים </b>
כמו כל דבר בחיים, גם בטוויטר- עקביות, נחישות ומאמץ זה שם המשחק. מי שרוצה באמת להצליח ולצבור אט אט קהל והשפעה ברשת, צריך להצטייד בהמון נחישות וסבלנות. יהיה עליכם להשקיע, להתנסות, לטעות ולתקן וחוזר חלילה. ולהמשיך להשתפר כל הזמן, תוך כדי תנועה.  
        </p>
        <v-divider style="padding-top: 25px !important" inset></v-divider>
        <v-card-actions>
          <v-btn color="success" v-if="user !== null" @click="logout"
            >Logout</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="info" v-if="user == null" @click="signIn">Login</v-btn>
        </v-card-actions>
      </v-card-text>
      <v-divider></v-divider>
    </v-card>
  </v-container>
</template>

<script>
import Api from "../api/";
import _ from "lodash";
import { Tweet } from "vue-tweet-embed";

export default {
  name: "App",
  data() {
    return {
      username: "",
      userResponse: null,
      ResponseOk: false,
      ResponseNotOk: false,
    };
  },
  components: {
    Tweet,
  },

  computed: {
    user() {
      return this.$store.getters.user;
    },
  },
  methods: {
    orderedTopUser: function (list) {
      return _.orderBy(list, "public_metrics.rank_in_top"); //this.userResponse.TopUsers, 'rank_in_top'
    },
    setTheme: function () {
      this.$vuetify.theme.dark = false;
      this.$vuetify.rtl = true;
    },
    logout() {
      this.userResponse = null;
      this.ResponseOk = false;
      this.ResponseNotOk = false;
      this.$store.dispatch("logout");
    },
    signIn() {
      this.$store.dispatch("signIn");
    },
    checkUser: function () {
      //Api.get("test.js")
      Api.get("getfollowers/" + this.user.userDetails[0].uid)
        .then((response) => {
          console.log(response.data);

          this.userResponse = response.data;
          this.userResponse.profile_image_url =
            this.userResponse.profile_image_url.replace("normal", "400x400");

          this.ResponseOk = true;
          this.ResponseNotOk = false;
        })
        .catch((error) => {
          this.ResponseOk = false;
          this.ResponseNotOk = true;
          console.log("error" + error);
        });
    },

    forTest() {
      this.userResponse =
        '{id: "1296533661781721089", name: "Lior Malenboim", username: "LMalenboim", Usercaption: "My caption", created_at: "2020-08-20T19:44:57.000Z", …}';
    },
  },
  beforeMount() {
    this.setTheme();
  },
};
</script>

<template>
  <v-container>  
			<v-card width="400" class="mx-auto mt-5">
				<v-card-title>
					<h3 class="display-1">Followers score</h3>      
				</v-card-title>
                <v-card-subtitle>
                  <h4>Login with Twitter to get your "Followers score" </h4>
                  <h4>The total amount of followers your followers have. </h4>                  
                </v-card-subtitle>
				<v-card-text>
               <v-card-actions>
					<v-btn color="success" v-if="user !== null" @click="logout" >Logout</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="info" v-if="user == null" @click="signIn" >Login</v-btn>
				</v-card-actions>
					<!-- <v-form>
						<v-text-field label="@Username" prepend-icon="mdi-account-circle" v-model="username" />
						<h2 v-if="user !== null" >Welcome {{ user.userDetails[0].displayName }}</h2>
                        <h2 v-if="user !== null" >ID {{ user.userDetails[0].uid }} 
            </h2>
					</v-form> -->    	
				</v-card-text>
				<v-divider></v-divider>  
            </v-card>

            <!-- Twitter user card -->
            <v-card width="400" class="mx-auto mt-5"  v-if="user !== null">
				<v-card-title>
                <v-avatar size="48px"><img alt="Avatar" :src="user.userDetails[0].photoURL">
                </v-avatar>
					<h5 >{{user.userDetails[0].displayName }}</h5>
				</v-card-title>                
				<v-card-text>
                  Lets see how many folloers your followers have and how many are on Top charts! 
				</v-card-text>
            <v-card-actions>	
					<v-spacer></v-spacer>
					<v-btn color="info" @click="checkUser"  >Check my followers score</v-btn>
				</v-card-actions> 
            </v-card>

            <!-- Response user card -->

            <v-card width="400" class="mx-auto mt-5"  v-if="ResponseOk && user !== null">
			
            <v-card-title>
                <v-avatar size="48px"><img alt="Avatar" :src="userResponse.profile_image_url">
                </v-avatar>
					<h3 >{{userResponse.name }}</h3>
				</v-card-title>                
                <v-card-subtitle>
                  <h5>@{{userResponse.username}}</h5>
                   <h3 class="font-weight-light "><b>{{userResponse.public_metrics.fof_count.toLocaleString()}}</b> Total followers of followers </h3>
             
                </v-card-subtitle>
                <v-app-bar   color="blue">
                   <v-toolbar-title class="text-h6 font-weight-light white--text" >My top followers: ({{this.userResponse.TopUsers.length}}/100)</v-toolbar-title>
                     <v-spacer></v-spacer>
            
                </v-app-bar>
                <v-flex d-flex>
                  <v-layout wrap>
                     <v-flex md4 v-for="topUser in orderedTopUser(this.userResponse.TopUsers)"  :key="topUser.rank_in_top">
                        <v-card  elevation="0" >			
                           <v-card-title>
                              <v-avatar size="48px"><img alt="Avatar" :src="topUser.profile_image_url">
                              </v-avatar>
                              
                           </v-card-title>                
                              <v-card-subtitle>
                                 <h5 >{{topUser.name }}</h5>
                                 <h5>@{{topUser.username}}</h5>
                                 <h5>{{topUser.public_metrics.followers_count.toLocaleString()}} Followers </h5>
                              </v-card-subtitle>
                        </v-card>
                     </v-flex>
                  </v-layout>
               </v-flex>
            </v-card>

                        <!-- Response Error card -->

            <v-card width="400" class="mx-auto mt-5" v-if="ResponseNotOk">
				<v-card-title>
					<h5 class="display-1">Twitter API as requests limit - please try again in 5 minutes...</h5>
                    
				</v-card-title>
            <v-divider></v-divider>
				<v-card-actions>
					<!-- <v-btn color="success">Register</v-btn> -->
					<v-spacer></v-spacer>
					<v-btn color="info" @click="checkUser"  >Try again</v-btn>
				</v-card-actions>
            </v-card>
				
          
      </v-container>
</template>

<script>
import Api from '../api/'
import _ from 'lodash';

export default {
	name: 'App',
	data() {
		return {
			username: '',
         userResponse:null,
         ResponseOk:false	,
         ResponseNotOk:false}
	},
   computed: {
       user () {
           return this.$store.getters.user
       },
       
    
   },
    methods:{
        orderedTopUser: function (list) {
    return _.orderBy(list, 'public_metrics.rank_in_top')//this.userResponse.TopUsers, 'rank_in_top'
  },
     setTheme: function() {
            this.$vuetify.theme.dark=false
     },
     logout() {
        this.userResponse=null
         this.ResponseOk=false
         this.ResponseNotOk=false
         this.$store.dispatch('logout')
    },
     signIn () {
            this.$store.dispatch('signIn')
        } ,
     checkUser: 
     function() {
         //Api.get("test.js")
         Api.get("getfollowers/" + this.user.userDetails[0].uid )
               .then(response => {
                  console.log(response.data);
                  
                  this.userResponse=response.data
                  this.userResponse.profile_image_url= this.userResponse.profile_image_url.replace("normal", "400x400");

                  this.ResponseOk=true
                  this.ResponseNotOk=false
               })
               .catch(error => {
this.ResponseOk=false
                  this.ResponseNotOk=true
                  console.log("error" + error);
               });
            
     },

 forTest(){
      this.userResponse='{id: "1296533661781721089", name: "Lior Malenboim", username: "LMalenboim", Usercaption: "My caption", created_at: "2020-08-20T19:44:57.000Z", …}'
 },
  },
 beforeMount(){
    this.setTheme()
 },
}
</script>

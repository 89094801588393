<template>
  <hello-world />
</template>

<script>
  import HelloWorld from '../components/HelloWorld'

  export default {
    name: 'Home',

    components: {
      HelloWorld,
    },
    methods:{
     setTheme: function() {
            this.$vuetify.theme.dark=true
     },
    },
    beforeMount(){
    this.setTheme()
 },
  }
</script>

import Vue from 'vue'
import Vuex from 'vuex'
import firebase from 'firebase'
import { firestore } from '../firebase'

Vue.use(Vuex)

export const store = new Vuex.Store({
    state: {
        user: null,
        token: null
    },
    getters: {
        user (state) {
            return state.user
        },
        token(state){
            return state.token
        }
    },
    mutations: {
        SET_USER (state, payload) {
            state.user = payload
        },
        LOGOUT (state, payload) {
            state.user = null
            state.token = null
        }
    },
    actions: {
        autoSignIn({ commit }, payload) {
            const newUser = {
                userDetails: payload.providerData
            }
            commit('SET_USER', newUser)
            //this.state.token =payload.providerData.token
        },
        signIn({ commit }) {
            var provider = new firebase.auth.TwitterAuthProvider();
            firebase.auth().signInWithPopup(provider)
            .then(result => {
                if (result.credential) {
                    /** @type {firebase.auth.OAuthCredential} */
                    var credential = result.credential;
              
                    // This gives you a the Twitter OAuth 1.0 Access Token and Secret.
                    // You can use these server side with your app's credentials to access the Twitter API.
                    this.state.token = credential.accessToken;
                    console.log(credential.accessToken)
                    //var secret = credential.secret;
                    // ...
                  }
                // The signed-in user info.
                var user = result.user.providerData[0];
                console.log(result.user)
                commit('SET_USER', user)
                console.log(user)
                var firebaseUser = {
                    displayName: user.displayName,
                    photoURL:user.photoURL,
                    id: user.uid,
                    accessToken: credential.accessToken,
                    secret: credential.secret,
                };
               
                firestore.collection("users").doc(user.uid).set(firebaseUser).then(() => {
                    console.log('user updated!')
                })
            
            }).catch(error => {
                alert(error)
                return 
            })
        },
        logout({ commit }) {
            firebase.auth().signOut().then(function () {
                commit('LOGOUT')
            }).catch(function (error) {
                alert(error)
                return
            });
        }
    }
})
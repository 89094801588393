import axios from 'axios';

export default 
    axios.create({
        baseURL: process.env.NODE_ENV === 'development' ? 
            //'http://mhome.keyops.org:9090/' :
            //'http://office.spear.rs:9090/' :
           // 'http://localhost:8080':'http://localhost:8080'
             'https://malenboim-website.oa.r.appspot.com/':
             'https://malenboim-website.oa.r.appspot.com/'
    });

<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-img
          :src="require('../assets/images/Malenboim_logo.svg')"
          class="my-3"
          contain
         

        />
      </v-col>

      <!-- <v-col class="mb-4">
        <h1 class="display-2 font-weight-bold mb-3">
          Welcome to my website :)
        </h1>

        <p class="subheading font-weight-regular">
          For help and collaboration with other Vuetify developers,
          <br>please join our online
          <a
            href="https://community.vuetifyjs.com"
            target="_blank"
          >Discord Community</a>
        </p>
      </v-col> -->

      <v-col
        class="mb-5"
        cols="12"
      >
        <h2 class="headline font-weight-bold mb-3">
          What's next?
        </h2>

        <v-row justify="center">
          <a
            v-for="(next, i) in whatsNext"
            :key="i"
            :href="next.href"
            class="subheading mx-3"
            target="_blank"
          >
            {{ next.text }}
          </a>
        </v-row>
      </v-col>

     
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'HelloWorld',

    data: () => ({
     
      whatsNext: [
        {
          text: 'About',
          href: 'https://www.linkedin.com/in/liormalenboim',
        },
        // {
        //   text: 'רעש וציוצים',
        //   href: 'https://raash-digital.com/',
        // },
        {
          text: 'Schedule a meeting',
          href: 'https://calendly.com/malenboim/meet',
        },
        {
          text: 'Twitter API Report',
          href: 'https://twitter.com/LMalenboim',
        },
        {
          text: 'Twebate',
          href: 'https://twebate.co',
        },
      ],
    }),
  }
</script>

/**
 * Firebase Login
 * Vuely comes with built in firebase login feature
 * You Need To Add Your Firsebase App Account Details Here
 */
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
//import router from '../router'

// Initialize Firebase 
const firebaseConfig = {
  apiKey: "AIzaSyAJ16tu1_H2DdcxPtT-HKUYxgoE6xXWsW8",
  authDomain: "malenboim-website.firebaseapp.com",
  //databaseURL: "https://shaked-web.firebaseio.com",
  projectId: "malenboim-website",
  storageBucket: "malenboim-website.appspot.com",
  messagingSenderId: "634267707537",
  appId: "1:634267707537:web:9723526ec68f0cde593730",
  measurementId: "G-CQBCC9E1D7"
};
firebase.initializeApp(firebaseConfig);


const auth = firebase.auth();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
const firestore = firebase.firestore();
const { Timestamp } = firebase.firestore


// Export types that exists in Firestore
// This is not always necessary, but it's used in other examples
const { TimeStamp, GeoPoint } = firebase.firestore
export { TimeStamp, GeoPoint }




const moviesCollection = firestore.collection('Movies')

const moviesCurrent = firestore.collection('Movies').where('last', '==', true)
const usersCollection = firestore.collection('users')


export {
    auth,
    googleAuthProvider,
    firebase,
    firestore,
    Timestamp,
    usersCollection,
    moviesCollection,
    moviesCurrent
};

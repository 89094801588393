<template>
	<v-app>
		<v-app-bar app color="grey darken-4" dark>
			<v-toolbar-title></v-toolbar-title>
			<v-spacer></v-spacer>
			<v-btn to="Home" text rounded>Home</v-btn>
			
						<!-- <v-btn to="Twitter" text rounded>Twitter</v-btn> -->


			<!-- <v-btn to="Login" text rounded>Login</v-btn> -->
		</v-app-bar>
		<v-main>
			<!-- Provides the application the proper gutter -->
    <v-container fluid>

      <!-- If using vue-router -->
      <router-view></router-view>
    </v-container>

		</v-main>
		
		<v-footer color="grey darken-3" padless>
			<v-layout justify-center wrap>
				<v-btn
					v-for="link in links"
					:key="link"
					color="white"
					:to="link"
					text
					rounded
					class="my-2"
				>
					{{ link }}
				</v-btn>
				<v-flex grey darken-4 py-4 text-center white--text xs12>
					{{ new Date().getFullYear() }} — <strong>Malenboim</strong>
				</v-flex>
			</v-layout>
		</v-footer>
	</v-app>
</template>

<script>
export default {
	name: 'App',
	data() {
		return {
			showPassword: false,
			links: ['Home']
		}
	}
}
</script>
import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../views/Login.vue'
import TwitterTools from '../views/TwitterTools.vue'
import Twitter2021 from '../views/Twitter2021.vue'


import { auth } from '../firebase'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: '',
    component: Home,
    meta: {
      requiresAuth: false,
      title:'Malenboim'
    }
  },
  {
    path: '/Home',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: false,
      title:'Malenboim'
    }
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login,
    meta: {
      requiresAuth: false,
      title:'Malenboim'
    }
  },
  {
    path: '/Twitter',
    name: 'Twitter Tools',
    component: TwitterTools,
    meta: {
      requiresAuth: false,
      title:'TwitterTools'
    }
  },
  {
    path: '/Twitter2021',
    name: 'Twitter End of 2021',
    component: Twitter2021,
    meta: {
      requiresAuth: false,
      title:'Twitter 2021 summary'
    }
  },
  {
    path: '/COVID-19',
    name: 'COVID-19 Dashboard',
    beforeEnter(to, from, next) {
      window.location.href = "https://datastudio.google.com/u/0/reporting/02b444b1-304e-4584-b389-788a3ff2c104/page/p_tz91du87mc";
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  }
})
router.beforeEach((to, from, next) => {
  //window.scrollTo(0, 0)
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)

  if (requiresAuth && !auth.currentUser) {
    next('/login')
  } else {
    next()
  }
})

export default router

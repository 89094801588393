<template>
  <v-container>  
			<v-card width="400" class="mx-auto mt-5">
				<v-card-title>
					<h1 class="display-1">Login</h1>
				</v-card-title>
				<v-card-text>
			
               
            <h2 v-if="user !== null" >Welcome {{ user.userDetails[0].displayName }} 
                
            </h2>
                        <h2 v-if="user !== null" >ID {{ user.userDetails[0].uid }} 
                
            </h2>
            <h2 v-if="token !== null" >accessToken {{ token }} 
                
            </h2>
          
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions>
					<v-btn color="success" v-if="user !== null" @click="logout" >Logout</v-btn>
					<v-spacer></v-spacer>
					<v-btn color="info" v-if="user == null" @click="signIn" >Login</v-btn>
				</v-card-actions>
			</v-card>
      </v-container>
</template>
<script>
export default {
  data () {
        return {
        }
    },
    computed: {
       user () {
           return this.$store.getters.user
       } ,
       token(){
           return this.$store.getters.token
       }
       
    },
  methods: {
    logout() {
      this.$store.dispatch('logout')
    },
     signIn () {
            this.$store.dispatch('signIn')
        } 
  }
}
</script>